import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    // In Japanese the characters are usually larger.
    fontSize: 14,
  },
});

export default theme;
