exports.onClientEntry = () => {
  window.zESettings = {
    webWidget: {
      offset: { horizontal: "0px", vertical: "0px" },
      helpCenter: {
        suppress: true,
        chatButton: {
          "*": "Ask us a question",
        },
      },
      launcher: {
        label: {
          "*": "Get help",
        },
        chatLabel: {
          "*": "Get help",
        },
      },
      badge: {
        layout: "image_only",
      },
      chat: {
        connectOnPageLoad: false,
      },
      contactOptions: {
        enabled: true,
        chatLabelOnline: { "*": "Live Chat" },
        chatLabelOffline: { "*": "Leave a message" },
      },
      answerBot: {
        avatar: {
          url: "https://zendesk.com/bot.png",
          name: {
            "*": "LabLog Bot",
          },
        },
      },
    },
  };
};
