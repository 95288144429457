import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  makeStyles,
  MenuItem,
  Popper,
} from "@material-ui/core";
import logo from "../images/main-logo-long.svg";
import { blueGrey, deepOrange, grey, orange } from "@material-ui/core/colors";
import { Link, navigate } from "gatsby";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import AutoSizer from "react-virtualized-auto-sizer";
import ChatBubbleIcon from "../images/chatBubble.svg";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import twitterIcon from "../images/twitter_light.svg";
import facebookIcon from "../images/facebook.svg";
import linkedInIcon from "../images/linkedIn.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  footerLink: {
    color: grey[100],
    fontWeight: 300,
    textDecoration: "none",
    "&:hover": {
      color: grey[50],
      textDecoration: "underline",
    },
  },
  menuButton: {
    marginRight: "28px",
    fontSize: "0.95em",
    color: blueGrey[800],
    textDecoration: "none",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      color: blueGrey[900],
      textDecoration: "underline",
    },
  },
  bigButton: {
    backgroundColor: deepOrange[800],
    fontSize: "0.95em",
    userSelect: "none",
    textDecoration: "none",
    padding: "4px 12px",
    borderRadius: "20px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: deepOrange[900],
    },
  },
  outlineButton: {
    border: `1px solid ${deepOrange[800]}`,
    fontSize: "0.95em",
    userSelect: "none",
    padding: "4px 12px",
    borderRadius: "20px",
    color: deepOrange[800],
    "&:hover": {
      border: `1px solid ${deepOrange[900]}`,
      color: deepOrange[900],
    },
  },
}));

export default function Layout({ children, location }) {
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const scroller = React.useRef(null);
  const timer = React.useRef(null);
  const mediumScreen = useMediaQuery("(min-width:768px)");
  const [menuPopover, setMenuPopover] = React.useState({
    anchor: null,
    open: false,
  });
  const [hasZE, setHasZe] = React.useState(false);
  const [showHello, setShowHello] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // mobile device
      setIsMobile(true);
      return;
    }
    setIsMobile(false);
  }, []);

  const handleMouseEntered = React.useCallback((event) => {
    const t = event.target;
    clearTimeout(timer.current);
    setMenuPopover((s) => ({ ...s, open: true, anchor: t }));
  }, []);

  const handleMouseOut = React.useCallback(() => {
    setMenuPopover((s) => ({ ...s, open: false }));
  }, []);

  React.useEffect(() => {
    setMenuPopover((s) => ({ ...s, open: false }));
    if (scroller.current) scroller.current.scrollTop = 0;
    else {
      console.log("scroll");
      const d = document.getElementById("__ll-main-container");
      if (d) d.scrollTop = 0;
    }
    setDrawer(false);
  }, [location]);

  const handleClickChatButton = React.useCallback(() => {
    var zdscript = document.createElement("script");
    zdscript.type = "text/javascript";
    zdscript.id = "ze-snippet";
    zdscript.src =
      "https://static.zdassets.com/ekr/snippet.js?key=f5051431-b227-4dca-95e2-5521839bea8a";
    document.getElementsByTagName("head")[0].appendChild(zdscript);
    setShowHello(true);
    setHasZe(true);

    window.zdonload = setInterval(
      function () {
        if (
          typeof window.zE !== "undefined" &&
          typeof window.zE.activate !== "undefined"
        ) {
          window.zE("webWidget", "hide");
          clearInterval(window.zdonload);
          window.zE(() => {
            setTimeout(() => {
              setShowHello(false);
              window.zE("webWidget", "open");
              window.zE("webWidget", "show");
            }, 500);
          });
        }
      },
      50,
      null
    );
  }, []);

  return (
    <Box
      position="absolute"
      fontFamily="Roboto"
      overflow="hidden"
      top="0"
      bottom="0"
      left="0"
      right="0"
    >
      {showHello && (
        <Box
          position="absolute"
          right="25px"
          bottom="15px"
          height="50px"
          bgcolor={deepOrange[700]}
          style={{
            borderRadius: "3px",
            cursor: "pointer",
            color: "#FFFFFF",
            fontWeight: "400",
            fontSize: "1em",
          }}
          onClick={handleClickChatButton}
          zIndex="1000"
        >
          <Box lineHeight="25px" p={1}>
            Hello! We are connecting you to an agent...
          </Box>
        </Box>
      )}
      {!hasZE && (
        <Box
          position="absolute"
          right="25px"
          bottom="15px"
          width="50px"
          height="50px"
          bgcolor={deepOrange[700]}
          style={{ borderRadius: "50%", cursor: "pointer" }}
          onClick={handleClickChatButton}
          zIndex="1000"
        >
          <img
            src={ChatBubbleIcon}
            alt="Chat support"
            width="30px"
            height="50px"
            style={{ display: "block", margin: "auto" }}
          />
        </Box>
      )}
      <Box
        display="flex"
        boxShadow="0px 5px 15px 1px rgba(50,50,50,0.55)"
        p={6}
        pt={2}
        position="relative"
        zIndex={10}
        pb={2}
        alignItems="bottom"
      >
        <Box>
          {1 === 3 && (
            <Popper
              open={menuPopover.open}
              onClose={() => {
                setMenuPopover((s) => ({ ...s, open: false }));
              }}
              anchorEl={menuPopover.anchor}
              placement="bottom-start"
              disablePortal
            >
              <Box
                minWidth="250px"
                bgcolor={grey[100]}
                boxShadow="0px 5px 15px 1px rgba(0,0,0,0.55)"
                zIndex="200"
                position="relative"
                onMouseEnter={() => {
                  if (timer.current) clearTimeout(timer.current);
                }}
                onMouseLeave={() => {
                  if (timer.current) clearTimeout(timer.current);
                  timer.current = setTimeout(handleMouseOut, 1000);
                }}
              >
                <Box ml={2} pt={1} fontWeight="500">
                  BY INDUSTRY
                </Box>
                <MenuItem>Academic Research</MenuItem>
                <MenuItem>Agriculture &amp; Horticulture</MenuItem>
                <MenuItem>Life Sciences Industry</MenuItem>
              </Box>
            </Popper>
          )}
          <Box>
            <Link to="/">
              <img src={logo} height="35px" alt="LabLog logo" />
            </Link>
          </Box>
          {mediumScreen && (
            <Box
              display="flex"
              marginTop="8px"
              onMouseEnter={() => {
                if (timer.current) clearTimeout(timer.current);
                timer.current = setTimeout(handleMouseOut, 1000);
              }}
            >
              <Box>
                <Link to="/how-lablog-works" className={classes.menuButton}>
                  Why LabLog
                </Link>
              </Box>
              <Box
                onMouseEnter={handleMouseEntered}
                onMouseLeave={() => {
                  if (timer.current) clearTimeout(timer.current);
                  timer.current = setTimeout(handleMouseOut, 1000);
                }}
              >
                <Link to="/lab-research-tools" className={classes.menuButton}>
                  Solutions
                </Link>
              </Box>
              <Box>
                <Link to="/app-security" className={classes.menuButton}>
                  Data Security
                </Link>
              </Box>
              <Box>
                <Link to="/fda" className={classes.menuButton}>
                  Compliance
                </Link>
              </Box>
            </Box>
          )}
        </Box>
        <Box flexGrow={1} />
        {mediumScreen ? (
          <Box>
            <Box display="flex" justifyContent="flex-end" mr={1}>
              <a
                className={classes.menuButton}
                style={{ marginRight: 0, marginBottom: "8px" }}
                href="https://e.labnotebook.app"
              >
                Log in
              </a>
            </Box>
            <Box display="flex">
              <Link to="/request-demo" style={{ textDecoration: "none" }}>
                <Box className={classes.bigButton}>Schedule Demo</Box>
              </Link>
              <Box width="10px" />
              <Link to="/get-started/create" style={{ textDecoration: "none" }}>
                <Box className={classes.outlineButton}>Create Account</Box>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                setDrawer((s) => !s);
              }}
            >
              <MenuIcon htmlColor={deepOrange[500]} />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawer}
              onClose={() => setDrawer(false)}
              keepMounted
            >
              <Box p={1} width="150px">
                <Box mt={4}>
                  <Link to="/how-lablog-works" className={classes.menuButton}>
                    Why LabLog
                  </Link>
                </Box>
                <Box mt={4}>
                  <Link to="/lab-research-tools" className={classes.menuButton}>
                    Solutions
                  </Link>
                </Box>
                <Box mt={4}>
                  <Link to="/app-security" className={classes.menuButton}>
                    Data Security
                  </Link>
                </Box>
                <Box mt={4}>
                  <Link to="/fda" className={classes.menuButton}>
                    Compliance
                  </Link>
                </Box>
                <Box mt={4}>
                  <Link to="/request-demo" style={{ textDecoration: "none" }}>
                    <Box className={classes.bigButton} textAlign="center">
                      Schedule Demo
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Drawer>
          </Box>
        )}
      </Box>
      <Box height="100%">
        <AutoSizer disableWidth>
          {({ height }) => (
            <Box
              height={height - 87}
              bgcolor={
                location.pathname.indexOf("get-started") > -1 ||
                location.pathname.endsWith("blog") ||
                location.pathname.endsWith("blog/")
                  ? grey[100]
                  : "#FFFFFF"
              }
              id="__ll-main-container"
              overflow={!isMobile ? "hidden" : "auto"}
            >
              {isMobile ? (
                <Box>
                  <Box minHeight={height - 247}>{children}</Box>
                  <Box>
                    <Box
                      display="flex"
                      pt={3}
                      pb={3}
                      pl={5}
                      pr={5}
                      color={grey[100]}
                      bgcolor={grey[900]}
                      flexWrap="wrap"
                      fontSize="0.86em"
                      justifyContent="space-between"
                    >
                      <Box width="200px">
                        <Box fontWeight="500" mt={1}>
                          LabLog
                        </Box>
                        <Box fontWeight="300" mt={2}>
                          1201 Seven Locks Road, Rockville, Maryland 20854
                        </Box>
                        <Box fontWeight="300" mt={1}>
                          Email: support@labnotebook.app
                        </Box>
                        <Box fontWeight="300" mt={1}>
                          Phone: +1 (833) 4-LabLog
                        </Box>
                      </Box>

                      <Box width="100px">
                        <Box fontWeight="500" mb={1} mt={1}>
                          About us
                        </Box>
                        <Link to="/about" className={classes.footerLink}>
                          Our story
                        </Link>

                        <Box fontWeight="300" mt={1}>
                          <a
                            href="https://help.labnotebook.app/hc/en-us"
                            className={classes.footerLink}
                          >
                            Documentation
                          </a>
                        </Box>
                        <Box fontWeight="300" mt={1}>
                          <Link to="/blog" className={classes.footerLink}>
                            Blog
                          </Link>
                        </Box>
                      </Box>

                      <Box width="200px">
                        <Box fontWeight="500" mb={1} mt={1}>
                          Legal
                        </Box>
                        <a
                          href="https://help.labnotebook.app/hc/en-us/articles/360053254431-Privacy-Policy"
                          className={classes.footerLink}
                        >
                          Privacy Policy
                        </a>
                        <Box fontWeight="300" mt={1}>
                          <Link to="/terms" className={classes.footerLink}>
                            Terms &amp; Conditions
                          </Link>
                        </Box>
                      </Box>

                      <Box width="200px">
                        <Box fontWeight="500" mb={1} mt={1}>
                          Policy
                        </Box>
                        <Link to="/app-security" className={classes.footerLink}>
                          Application security
                        </Link>
                        <Box fontWeight="300" mt={1}>
                          <Link
                            to="/request-whitepaper"
                            className={classes.footerLink}
                          >
                            Software principles
                          </Link>
                        </Box>
                      </Box>
                      <Box width="250px" />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="90px"
                        pr={3}
                      >
                        <Box>
                          <a href="https://www.linkedin.com/company/lablog">
                            <img src={linkedInIcon} height="20px" />
                          </a>
                        </Box>
                        <Box ml={3}>
                          <a href="https://twitter.com/lab_log">
                            <img src={twitterIcon} height="20px" />
                          </a>
                        </Box>
                        <Box ml={3}>
                          <a href="https://www.facebook.com/labnotebookapp">
                            <img src={facebookIcon} height="20px" />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <PerfectScrollbar
                  containerRef={(ref) => {
                    scroller.current = ref;
                  }}
                >
                  <Box>
                    <Box minHeight={height - 247}>{children}</Box>
                    <Box>
                      <Box
                        display="flex"
                        pt={3}
                        pb={3}
                        pl={5}
                        pr={5}
                        color={grey[100]}
                        bgcolor={grey[900]}
                        flexWrap="wrap"
                        fontSize="0.86em"
                        justifyContent="space-between"
                      >
                        <Box width="200px">
                          <Box fontWeight="500" mt={1}>
                            LabLog
                          </Box>
                          <Box fontWeight="300" mt={0.5}>
                            Federal Employer ID Number:
                            <br />
                            81 - 3947253
                          </Box>
                          <Box fontWeight="300" mt={0.5}>
                            1201 Seven Locks Road, Rockville, Maryland 20854,
                            United States
                          </Box>
                          <Box fontWeight="300" mt={1.5}>
                            Registered in England No. 13478444.
                            <br />
                            VAT No. GB 384 5765 51.
                            <br />
                            71-75 Shelton Street,
                            <br />
                            Covent Garden, London, England, WC2H 9JQ
                          </Box>
                          <Box fontWeight="300" mt={1}>
                            Email: support@labnotebook.app
                            <br /> Phone: +1 (833) 4-LabLog (USA)
                            <br />
                            Phone: 0203 829 2883 (UK)
                          </Box>
                          <Box mt={1}></Box>
                        </Box>

                        <Box width="100px">
                          <Box fontWeight="500" mb={1} mt={1}>
                            About us
                          </Box>
                          <Link to="/about" className={classes.footerLink}>
                            Our story
                          </Link>

                          <Box fontWeight="300" mt={1}>
                            <a
                              href="https://help.labnotebook.app/hc/en-us"
                              className={classes.footerLink}
                            >
                              Documentation
                            </a>
                          </Box>
                          <Box fontWeight="300" mt={1}>
                            <Link to="/blog" className={classes.footerLink}>
                              Blog
                            </Link>
                          </Box>
                        </Box>

                        <Box width="200px">
                          <Box fontWeight="500" mb={1} mt={1}>
                            Legal
                          </Box>
                          <a
                            href="https://help.labnotebook.app/hc/en-us/articles/360053254431-Privacy-Policy"
                            className={classes.footerLink}
                          >
                            Privacy Policy
                          </a>
                          <Box fontWeight="300" mt={1}>
                            <Link to="/terms" className={classes.footerLink}>
                              Terms &amp; Conditions
                            </Link>
                          </Box>
                        </Box>

                        <Box width="200px">
                          <Box fontWeight="500" mb={1} mt={1}>
                            Policy
                          </Box>
                          <Link
                            to="/app-security"
                            className={classes.footerLink}
                          >
                            Application security
                          </Link>
                          <Box fontWeight="300" mt={1}>
                            <Link
                              to="/request-whitepaper"
                              className={classes.footerLink}
                            >
                              Software principles
                            </Link>
                          </Box>
                        </Box>
                        <Box width="250px" />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="90px"
                          pr={3}
                        >
                          <Box>
                            <a href="https://www.linkedin.com/company/lablog">
                              <img src={linkedInIcon} height="20px" />
                            </a>
                          </Box>
                          <Box ml={3}>
                            <a href="https://twitter.com/lab_log">
                              <img src={twitterIcon} height="20px" />
                            </a>
                          </Box>
                          <Box ml={3}>
                            <a href="https://www.facebook.com/labnotebookapp">
                              <img src={facebookIcon} height="20px" />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </PerfectScrollbar>
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}
